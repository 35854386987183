const firstVisit = async () => {
  const originReferer = localStorage.getItem('Origin-Referer');

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const mainUrl = import.meta.env.PUBLIC_SERVICE_MAIN_URL;

  const repeat_visit = params.get('repeat_visit');

  const visitParams = {
    webmaster: params.get('webmaster'),
    stream: params.get('stream'),
    partner_stream: params.get('partner_stream'),
    partner_click_id: params.get('partner_click_id'),
    subs: {
      sub1: params.get('sub1'),
      sub2: params.get('sub2'),
      sub3: params.get('sub3'),
      sub4: params.get('sub4'),
      sub5: params.get('sub5'),
    },
  };

  const cookies = document.cookie.split('; ').reduce((prev, current) => {
    const [name, value] = current.split('=');
    // @ts-ignore
    prev[name] = value;
    return prev;
  }, {});

  const resetSearchParams = () => {
    if (!originReferer) {
      localStorage.setItem('Origin-Referer', window.location.href);
    }
    const url =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;
    window.history.replaceState({}, document.title, url);
  };

  if (repeat_visit === '1') {
    try {
      await fetch(`${mainUrl}/v1/stat/visit/repeat?is_mobile=false`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ partner_params: visitParams }),
      });
      resetSearchParams();
    } catch (error) {
      resetSearchParams();
      console.error('Failed to fetch:', error);
    }
  } else {
    // @ts-ignore
    if (!cookies.first_visit_params) {
      try {
        const response = await fetch(
          `${mainUrl}/v1/stat/visit?is_mobile=false`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ partner_params: visitParams }),
          },
        );

        const data = await response.json();
        const date = new Date();
        date.setDate(date.getDate() + 365);
        document.cookie = `first_visit_params=${JSON.stringify(data)}; expires=${date.toUTCString()}; path=/`;
        resetSearchParams();
      } catch (error) {
        resetSearchParams();
        console.error('Failed to fetch:', error);
      }
    } else {
      resetSearchParams();
    }
  }
};

(async () => {
  try {
    await firstVisit();
  } catch (error) {
    console.error('Failed to run firstVisit:', error);
  }
})();
