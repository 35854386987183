document.addEventListener('DOMContentLoaded', (event) => {
  const observeElement = (
    element: Element,
    handler: (isIntersecting: boolean) => void,
  ) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        handler(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    observer.observe(element);
  };
  const headTitle = document.getElementById('head-title');
  const benefitsWrapper = document.getElementById('benefits-wrapper');
  const registerTitle = document.getElementById('register-title');
  const registerRow = document.getElementById('register-row');
  const registerBox = document.getElementById('register-box');
  const registerSubmit = document.getElementById('register-submit');
  const eventsTitle = document.getElementById('events-title');
  const eventsSlider1 = document.getElementById('events-slider-1');
  const eventsSlider2 = document.getElementById('events-slider-2');
  const loader = document.getElementById('global-loader');

  const elements = [
    headTitle,
    benefitsWrapper,
    registerTitle,
    registerRow,
    registerBox,
    registerSubmit,
    eventsTitle,
    eventsSlider1,
    eventsSlider2,
  ];

  if (loader) {
    const loaderObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && loader.style.display === 'none') {
          loaderObserver.disconnect();

          elements.forEach((element) => {
            if (element) {
              observeElement(element, (isIntersecting) => {
                if (isIntersecting) {
                  element.classList.add('show');
                }
              });
            }
          });
        }
      });
    });

    loaderObserver.observe(loader, { attributes: true });
  }
});
